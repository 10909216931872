import { END_POINTS } from "../../helpers";
import emptySplitApi from "../../utils/rtk";

type CommonResponseType = {
  statusCode: number;
  message: string;
};

export const mintApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getMintedNft: builder.query<
      CommonResponseType & { data: any },
      {
        page: number;
        size: number;
        type: number;
        sort?: number | undefined | string;
        categoryId?: string | undefined;
        sortType?: string | undefined;
        claimType?: string | undefined;
      }
    >({
      query: ({ page, size, type, sort, sortType, categoryId, claimType }) => ({
        url: `${END_POINTS.myMintedNft}?type=${type}&page=${page}&size=${size}${
          sort ? `&sort=${sort}` : ""
        }${sortType ? `&sortType=${sortType}` : ""}${
          categoryId ? `&categoryId=${categoryId}` : ""
        }${claimType ? `&claimType=${claimType}` : ""}`,
        method: "GET",
      }),
    }),

    mintNft: builder.mutation<
      CommonResponseType & { data: any },
      {
        nftId: string;
      }
    >({
      query: ({ nftId }) => ({
        url: `${END_POINTS.mintMyNft}/${nftId}`,
        method: "PUT",
      }),
    }),
  }),
});

export const { useLazyGetMintedNftQuery, useMintNftMutation } = mintApi;
