export const SOCIAL_MEDIA_TYPE = {
  1: "FACEBOOK",
  2: "TELEGRAM",
  3: "TWITTER",
  4: "LINKEDIN",
} as any;

export const NFTS_STATUS = {
  1: "Draft",
  4: "Declined",
  3: "Accepted",
  2: "Verification Required",
};

export const NFT_TYPE = {
  1: "PASSIVE",
  2: "MINTED",
};
