import { AuthCommon } from "../../../../components";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useAppDispatch } from "../../../../hooks/store";
import {
  useLazyGetUserQuery,
  usePostLoginMutation,
} from "../../../../services/auth";
import { setCredentials } from "../../../../reducers/authSlice";
import {
  Loader,
  STORAGE_KEYS,
  errorToast,
  getFromStorage,
  removeFromStorage,
  setToStorage,
} from "../../../../helpers";
import * as Yup from "yup";
import { useFormik } from "formik";
import { generateEncryptedKeyBody } from "../../../../utils/crypto";
import { CommonBody } from "../../../../types/General";

const LoginContent = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [getUser] = useLazyGetUserQuery();
  const [loginMutation, { isLoading }] = usePostLoginMutation();
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
    const inputEl = document.getElementById("password");
    if (inputEl) {
      setTimeout(() => {
        if (inputEl instanceof HTMLInputElement) {
          inputEl.setSelectionRange(inputEl.value.length, inputEl.value.length);
        }
      }, 0);
    }
  };

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  const getUserDetails = async () => {
    const token = getFromStorage(STORAGE_KEYS.token);
    try {
      const result = await getUser({}).unwrap();
      if (result?.statusCode === 200 && token) {
        dispatch(
          setCredentials({
            user: result?.data,
            token: token,
          })
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      remember: false,
    },
    validationSchema: Yup.object({
      remember: Yup.boolean(),
      email: Yup.string()
        .required("Email is required!")
        .matches(
          /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
          "Enter a valid email address!"
        ),
      password: Yup.string()
        .required("Password is required!")
        .min(8, "Your password must be at least 8 characters"),
    }),
    onSubmit: async (values) => {
      formik.setSubmitting(true);
      let body = {
        email: formik.values.email,
        password: formik.values.password,
        deviceType: "WEB",
        deviceToken: "1234",
      };

      try {
        let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
        const response = await loginMutation(encryptedBody).unwrap();
        if (response?.statusCode === 200) {
          setToStorage(STORAGE_KEYS.token, response?.data?.token || "");
          dispatch(
            setCredentials({
              user: response?.data,
              token: response?.data?.token || "",
            })
          );
          getUserDetails();
          if (formik.values.remember) {
            setToStorage(STORAGE_KEYS.credentials, JSON.stringify(body));
          } else {
            removeFromStorage(STORAGE_KEYS.credentials);
          }
          if (response?.data?.isProfileComplete) {
            navigate("/");
          } else {
            navigate("/profile-setup");
          }
        }
      } catch (error: any) {
        errorToast(error?.data?.message || "");
        console.log(error);
      }
    },
  });

  useEffect(() => {
    const data = getFromStorage(STORAGE_KEYS.credentials);
    if (data) {
      const rememberData = JSON.parse(`${data}`);
      formik.setFieldValue("password", rememberData?.password);
      formik.setFieldValue("email", rememberData?.email);
      formik.setFieldValue("remember", true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Loader isLoad={isLoading} />
      <section className="auth_section">
        <div className="conta_iner">
          <div className="gap_p">
            <AuthCommon type="login" />
            <div className="right_side">
              <form
                action=""
                className="form hd_3"
                onSubmit={formik.handleSubmit}
              >
                <h2>Sign In</h2>
                <div className="form_control">
                  <label>Username or email address *</label>
                  <TextField
                    hiddenLabel
                    placeholder="Email Address"
                    fullWidth
                    name="email"
                    className="text_field"
                    type="email"
                    variant="outlined"
                    id="email"
                    onChange={(val) => {
                      if (
                        val.target.value === " " ||
                        val.target.value === "."
                      ) {
                      } else {
                        formik.handleChange(val);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                    helperText={formik.touched.email && formik.errors.email}
                  />
                </div>
                <div className="form_control">
                  <label>Password *</label>
                  <TextField
                    hiddenLabel
                    placeholder="Enter Password"
                    fullWidth
                    name="password"
                    type={showPassword ? "text" : "password"}
                    variant="outlined"
                    className="text_field"
                    id="password"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.password}
                    helperText={
                      formik.touched.password && formik.errors.password
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment className="eye_btn" position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                <div className="form_control d_flex">
                  <div className="remember_box">
                    <FormControlLabel
                      control={<Checkbox />}
                      checked={formik.values.remember}
                      name="remember"
                      onChange={formik.handleChange}
                      label="Remember me"
                    />
                  </div>
                  <a
                    // href="javacript:void(0)"
                    className="anchor_link"
                    onClick={() => navigate("/forgot-password")}
                  >
                    Forgot Password?
                  </a>
                </div>
                <div className="form_btn">
                  <Button
                    className="btn btn_primary"
                    color="primary"
                    size="large"
                    type="submit"
                    variant="contained"
                    // onClick={() => navigate("/")}
                  >
                    SIGN IN
                  </Button>
                </div>
                <div className="form_bottom">
                  <p>
                    Don’t have an account?{" "}
                    <a
                      // href="javacript:void(0)"
                      onClick={() => navigate("/signup")}
                      style={{cursor:'pointer'}}
                    >
                      Register Now
                    </a>
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default LoginContent;
