import { useNavigate } from "react-router-dom";

const CorporateSitesSection2 = () => {
    const navigate = useNavigate();

    return (
        <section className="Corp_sc2 u_spc">
            <div className="conta_iner">
                <div className="inner ub_spc">
                    <div className="gap_p">
                        <div className="left_s">
                            <figure><img src="/images/chatbot-mobile.png" alt="" /></figure>
                        </div>
                        <div className="right_s">
                            <div className="s_hd">
                                <h2>We are a technology company focused on making recycling not just a conscientious choice but also an engaging and rewarding habit, where impact and profitability converge.</h2>
                                <p>Our platform impact2earn®, is an innovative AI Chatbot & Web3 Recycling Rewards platform. We’ve designed an AI chatbot, capable of identifying and categorizing types of recyclable materials and with human-like chat and voice capabilities, can guide you toward the nearest e-waste collection point. Based on the e-waste you recycle, you’ll earn digital NFT rewards minted from certified green blockchains. These NFTs can then be traded on our NFT marketplace for discounts on a wide array of goods, services and digital assets.</p>
                                <button className="btn btn_lg btn_primary">Learn More</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="iconBoxes">
                    <div className="gap_m hd_5">
                        <div className="iconBoxes_single">
                            <figure><img src="/images/human-like-chatbot.svg" alt="" /></figure>
                            <h3>Human-like Chatbot</h3>
                            <p>We use Deep Learning Frameworks to improve the efficiency of our chatbot’s natural language processing (NLP) capabilities, making it more responsive and able to better understand user queries.</p>
                        </div>
                        <div className="iconBoxes_single">
                            <figure><img src="/images/image-recognition.svg" alt="" /></figure>
                            <h3>Image Recognition</h3>
                            <p>We use Image Recognition technology for identifying different types of waste materials through images. We also continually train our models, thus improving and optimizing our ability to identify new and different types of materials.</p>
                        </div>
                        <div className="iconBoxes_single">
                            <figure><img src="/images/blockchain-integration.svg" alt="" /></figure>
                            <h3>Multi-Blockchain Integration</h3>
                            <p>We’ve designed a solution that helps reduce blockchain’s environmental impact. By focusing on multi-blockchain interoperability, we’ve integrated with a selection of the world’s most sustainable blockchains.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default CorporateSitesSection2;
