/* eslint-disable jsx-a11y/img-redundant-alt */
import { useNavigate } from "react-router-dom";

const LearnHow_Process = () => {
    const navigate = useNavigate();

    return (
        <section className="learnHow_process u_spc">
            <div className="conta_iner">
                <div className="s_head text_center">
                    <h2>Below, we walk you through the process of using our platform. From recycling to receiving your NFT rewards.</h2>
                </div>
                <div className="processBox">
                    <div className="gap_m hd_4">
                        <div className="processBox_single">
                            <figure><img src="/images/process_icon_1.svg" alt="Icon" /></figure>
                            <h3>Getting Started</h3>
                            <p>Open a chat with our bot on Telegram and share your location. Open here: https://t.me/bantgo_bot</p>
                        </div>
                        <div className="processBox_single">
                            <figure><img src="/images/process_icon_2.svg" alt="Icon" /></figure>
                            <h3>View Locations</h3>
                            <p>Receive a list of recycling collection points around you with the option to zoom in on the map for better visibility.</p>
                        </div>
                        <div className="processBox_single">
                            <figure><img src="/images/process_icon_3.svg" alt="Icon" /></figure>
                            <h3>Adding a Place</h3>
                            <p>You can also suggest new collection points and benefit from them permanently.</p>
                        </div>
                        <div className="processBox_single">
                            <figure><img src="/images/process_icon_4.svg" alt="Icon" /></figure>
                            <h3>Photography</h3>
                            <p>Take a photo of your item, upload the image, and place the e-waste at the collection point.</p>
                        </div>
                        <div className="processBox_single">
                            <figure><img src="/images/process_icon_5.svg" alt="Icon" /></figure>
                            <h3>Identify and Mint</h3>
                            <p>Our AI identifies the item and creates an NFT containing impact2earn credits for you.</p>
                        </div>
                        <div className="processBox_single">
                            <figure><img src="/images/process_icon_6.svg" alt="Icon" /></figure>
                            <h3>Receiving NFT</h3>
                            <p>Claim your NFT, which can now be used on our marketplace or cashed out.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default LearnHow_Process;
