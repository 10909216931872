import React, { Dispatch, SetStateAction } from "react";
import { Box, Button, Modal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

type props = {
  openLogout: boolean;
  setOpenLogout: Dispatch<SetStateAction<boolean>>;
  handleLogout: () => void;
};

const LogoutModal = ({ openLogout, setOpenLogout, handleLogout }: props) => {
  return (
    <Modal
      open={openLogout}
      onClose={() => setOpenLogout(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="logout_modal"
    >
      <Box className="modal_body">
        <CloseIcon onClick={() => setOpenLogout(false)} className="close" />
        <div className="modal_content">
          <h2 className="modal_title">Are you sure, you want to logout?</h2>
          <div className="btn_flex">
            <Button
              className="btn btn_primary"
              onClick={() => {
                setOpenLogout(false);
                handleLogout();
              }}
            >
              Yes
            </Button>
            <Button
              className="btn btn_danger"
              onClick={() => setOpenLogout(false)}
            >
              No
            </Button>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default LogoutModal;
