import { useNavigate } from "react-router-dom";

const NoDataFound = (props: any) => {
  const navigate = useNavigate();

  return (
    <>
      <div className="no_date_sc hd_4">
        <figure>
          <img src={props?.image || '"/images/noData.png"'} alt="noData" />
        </figure>
        <h2>{props?.text || "No Data Found"}</h2>

        <div
          onClick={() => navigate("/", { replace: true })}
          className="btn btn_primary"
        >
          Back to Home
        </div>
      </div>
    </>
  );
};

export default NoDataFound;
