/* eslint-disable react/jsx-no-target-blank */
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Checkbox, FormControlLabel, TextField } from "@mui/material";
import { useNewsletterFormMutation } from "../../services/cms";
import * as Yup from "yup";
import { useFormik } from "formik";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody } from "../../types/General";
import { Loader, errorToast, successToast } from "../../helpers";

const Footer = () => {
  const navigate = useNavigate();
  const [newsletterFormMethod] = useNewsletterFormMutation();

  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
      telegram: "",
      acceptConditions: false,
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required("Email is required")
        .matches(
          /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
          "Enter a valid email address"
        ),
      telegram: Yup.string()
        .required("Telegram Id is required")
        .max(40, "Maximum 40 character are allowed")
        .min(6, "Telegram Id must be at least 6 characters")
        .matches(/^[^@]*$/, "Telegram Id cannot contain '@'"),
    }),
    onSubmit: async (values: any, { resetForm }) => {
      formik.setSubmitting(true);
      let body = {
        telegram: formik.values.telegram,
        email: formik.values.email,
      };
      if (!formik.values.acceptConditions) {
        errorToast("Please accept privacy policy to proceed further");
        return;
      }

      try {
        setLoading(true);
        let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
        const response = await newsletterFormMethod(encryptedBody).unwrap();
        setLoading(false);
        if (response?.statusCode === 200) {
          successToast("Newsletter Form Submitted successfully");
          resetForm();
        } else {
          errorToast(response?.message || "");
        }
      } catch (error: any) {
        setLoading(false);
        errorToast(error?.data?.message || "");
      }
    },
  });

  const scrollToTop = () => {
    if (window) {
      window.scrollTo({ top: 0, behavior: "auto" });
    }
  };

  return (
    <>
      <footer className="site_footer">
        <div className="conta_iner">
          <div className="main_footer hd_6">
            <div className="single">
              <a
                className="site_logo"
                // href="javacript:void(0)"
                onClick={() => navigate("/")}
              >
                <figure>
                  <img src="/images/impact2earn_logo.png" alt="Logo" />
                </figure>
              </a>
              <p >
                Incentivizing sustainable recycling behaviors globally through a
                WEB3 Recycling Rewards Platform
              </p>
              <ul className="social_icons">
                <li>
                  <a href="https://t.me/impact_to_earn" target="_blank">
                    <img src="/images/telegram_icon.svg" alt="Telegram" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/company/bantgo/"
                    target="_blank"
                  >
                    <img src="/images/linkedin_icon.svg" alt="Linkedin" />
                  </a>
                </li>
                <li>
                  <a href="https://www.facebook.com/BANTgo" target="_blank">
                    <img src="/images/facebook_icon.svg" alt="Facebook" />
                  </a>
                </li>
                <li>
                  <a href="https://www.twitter.com/ban_tgo" target="_blank">
                    <img src="/images/twitter_icon.svg" alt="Twitter" />
                  </a>
                </li>
              </ul>
            </div>
            <div className="single">
              <h2>Useful Links</h2>
              <ul>
                <li>
                  <a
                    // onClick={() => {
                    //   if (window) {
                    //     window.open("https://t.me/bantgo_bot)", "_blank");
                    //   }
                    // }}
                    // href="https://t.me/impact_to_earn"
                    href="https://t.me/bantgo_bot"
                    target="_blank"
                    style={{cursor:'pointer'}}
                  >
                    AI Chat Bot (Telegram)
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => navigate("/contact-us")}
                    // href="javacript:void(0)"
                    style={{cursor:'pointer'}}
                  >
                    Help Center
                  </a>
                </li>
                <li>
                  <a
                    // href="javacript:void(0)"
                    onClick={() => {scrollToTop(); navigate("/privacy-policy")}}
                    style={{cursor:'pointer'}}
                  >
                    Privacy Policy
                  </a>
                </li>
                {/* <li>
                  <a
                    href="javacript:void(0)"
                    onClick={() => navigate("/disclaimer")}
                  >
                    Disclaimer
                  </a>
                </li> */}
                <li>
                  <a
                    // href="javacript:void(0)"
                    onClick={() => navigate("/terms")}
                    style={{cursor:'pointer'}}
                    
                  >
                    Terms of Use
                  </a>
                </li>
              </ul>
            </div>
            <div className="single">
              <h2>Our Company</h2>
              <ul>
                {/* <li>
                  <a
                    href="javacript:void(0)"
                    onClick={() => navigate("/terms")}
                  >
                    Terms & Condition
                  </a>
                </li> */}
                <li>
                  <a
                    // href="javacript:void(0)"
                    // onClick={() => navigate("/corporate-sites")}
                    onClick={() => {
                      if (window) {
                        window.open("http://www.bantgo.ae/", "_blank");
                      }
                    }}
                    style={{cursor:'pointer'}}
                  >
                    Corporate Site
                  </a>
                </li>
                <li>
                  <a href="https://t.me/impact_to_earn" target="_blank">
                    Telegram Community
                  </a>
                </li>
                <li>
                  <a 
                  // href="javacript:void(0)" 
                  style={{cursor:'pointer'}}
                  onClick={() => navigate("/news")}>
                    News
                  </a>
                </li>
                <li>
                  <a
                    // href="javacript:void(0)"
                    style={{cursor:'pointer'}}
                    onClick={() => navigate("/contact-us")}
                  >
                    Contact Us
                  </a>
                </li>
              </ul>
            </div>
            <div className="single">
              <h2>Stay Informed By Newsletter</h2>
              <form onSubmit={formik.handleSubmit} className="form">
                <div className="form_control">
                  <TextField
                    hiddenLabel
                    placeholder="Email Address"
                    fullWidth
                    name="email"
                    type="email"
                    variant="outlined"
                    onChange={(val) => {
                      formik.handleChange(val);
                    }}
                    value={formik.values.email}
                    onBlur={formik.handleBlur}
                    id="email"
                  />
                  {Boolean(formik.touched.email && formik.errors.email) ? (
                    <span className="helper-text">
                      <h6 className="err_msg">
                        {(formik.touched.email && formik.errors.email) as any}
                      </h6>
                    </span>
                  ) : undefined}
                </div>
                <div className="form_control">
                  <TextField
                    hiddenLabel
                    placeholder="Your Telegram*"
                    fullWidth
                    name="telegram"
                    type="text"
                    variant="outlined"
                    onChange={(val) => {
                      if (
                        val.target.value === " " ||
                        val.target.value === "." ||
                        val.target.value?.includes("@")
                      ) {
                      } else {
                        formik.handleChange(val);
                      }
                    }}
                    value={formik.values.telegram}
                    onBlur={formik.handleBlur}
                    id="telegram"
                  />
                  {Boolean(
                    formik.touched.telegram && formik.errors.telegram
                  ) ? (
                    <span className="helper-text">
                      <h6 className="err_msg">
                        {
                          (formik.touched.telegram &&
                            formik.errors.telegram) as any
                        }
                      </h6>
                    </span>
                  ) : undefined}
                </div>
                <div className="form_control">
                  <div className="remember_box">
                    <FormControlLabel
                      control={<Checkbox />}
                      checked={formik.values.acceptConditions}
                      label=""
                      name="acceptConditions"
                      onChange={formik.handleChange}
                    />
                    {/* <div className="text_lab">
                      Accept{"  "}
                      <a
                        onClick={() => navigate("/terms")}
                        href="javascript:void(0)"
                      >
                        Terms of Use
                      </a>
                    </div> */}
                    <p className="text_lab">
                      By submitting, I agree to our{" "}
                      <a
                        // href="javacript:void(0)"
                        style={{cursor:'pointer'}}
                        onClick={() => {scrollToTop(); navigate("/privacy-policy")}}
                      >
                        Privacy Policy
                      </a>
                      .
                    </p>
                  </div>
                </div>
                <div className="form_btn">
                  <Button
                    className="btn btn_primary"
                    color="primary"
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Submit
                  </Button>
                </div>
              </form>
            </div>
          </div>
          <div className="copywrite_footer">
            <p>Copyright by BANTgo impact2earn. All Rights Reserved.</p>
            <p>impact2earn simplifies: recycle, earn, and enjoy</p>
          </div>
        </div>
      </footer>
      {/* <div className="chatBot_icon">
        <figure>
          <img src="/images/chatBot_icon.svg" alt="Icon" />
        </figure>
      </div> */}
    </>
  );
};

export default Footer;
