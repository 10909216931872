import { END_POINTS } from "../../helpers";
import { CommonBody } from "../../types/General";
import emptySplitApi from "../../utils/rtk";

type CommonResponseType = {
  statusCode: number;
  message: string;
};

export const categoryApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getCategory: builder.query<
      CommonResponseType & { data: any },
      { page: number; size: number; search: string; pagination: boolean }
    >({
      query: ({ page, size, search, pagination }) => ({
        url: `${END_POINTS.getCategory}?page=${page}&size=${size}&search=${search}&pagination=${pagination}`,
        method: "GET",
      }),
    }),

    getSubCategory: builder.query<
      CommonResponseType & { data: any },
      {
        catId: any;
        page: number;
        size: number;
        search: string;
        pagination: boolean;
      }
    >({
      query: ({ catId, page, size, search, pagination }) => ({
        url: `${END_POINTS.getSubCat}/?page=${page}&size=${size}&search=${search}&pagination=${pagination}`,
        method: "GET",
      }),
    }),

    getNftsBySubCat: builder.query<
      CommonResponseType & { data: any },
      { subCatId: any; type?: number | undefined }
    >({
      query: ({ subCatId, type }) => ({
        url: `${END_POINTS.nftBySubCategoryId}/${subCatId}${
          type ? `?type=${type}` : ""
        }`,
        method: "GET",
      }),
    }),

    bidOnNft: builder.mutation<
      { statusCode: number; data: any; message: string },
      {
        body: CommonBody;
        nftId: string;
      }
    >({
      query: ({ body, nftId }) => ({
        url: `${END_POINTS.bidOnNft}/${nftId}`,
        method: "POST",
        body,
      }),
    }),

    getAllNfts: builder.query<
      CommonResponseType & { data: any },
      {
        page: number;
        size: number;
        search: string;
        sort?: number | undefined | string;
        categoryId?: string | undefined;
        sortType?: string | undefined;
        type: number;
      }
    >({
      query: ({ page, size, search, sort, sortType, categoryId, type }) => ({
        url: `${
          END_POINTS.allNfts
        }?type=${type}&page=${page}&size=${size}&search=${search}${
          sort ? `&sort=${sort}` : ""
        }${sortType ? `&sortType=${sortType}` : ""}${
          categoryId ? `&categoryId=${categoryId}` : ""
        }`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useLazyGetCategoryQuery,
  useLazyGetSubCategoryQuery,
  useLazyGetNftsBySubCatQuery,
  useBidOnNftMutation,
  useLazyGetAllNftsQuery,
} = categoryApi;
