/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/img-redundant-alt */
import { useNavigate } from "react-router-dom";
import SearchBar from "../../../components/SearchBar";
import { Dispatch, Key, SetStateAction } from "react";
import SitePagination from "../../../components/sitePagination";
import { isValidInput } from "../../../utils/validation";
import { Loader } from "../../../helpers";

const ExploreCategory_Category = ({
  categories,
  page,
  setPage,
  totalPages,
  searchTerm,
  setDebouncedSearchTerm,
  setSearchTerm,
  loading,
  totalCount,
}: {
  categories: any;
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
  totalPages: number;
  searchTerm?: string;
  setDebouncedSearchTerm: Dispatch<SetStateAction<string>>;
  setSearchTerm: Dispatch<SetStateAction<string>>;
  loading: boolean;
  totalCount: number;
}) => {
  const navigate = useNavigate();

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  return (
    <section className="ec_category_sc ub_spc">
      <Loader isLoad={loading} />
      <div className="conta_iner">
        <div className="s_head f_width s_head_flex">
          <h1>Explore NFT’s by Category</h1>
          <div className="right_s">
            <SearchBar
              searchTerm={searchTerm}
              setDebouncedSearchTerm={setDebouncedSearchTerm}
              value={searchTerm}
              onCross={() => {
                setSearchTerm("");
                setDebouncedSearchTerm("");
              }}
              onChange={(val: any) => {
                if (isValidInput(val.target.value)) {
                  setSearchTerm(val.target.value);
                }
              }}
            />
          </div>
        </div>
        <div className="category_listing hd_4">
          <div className="gap_m">
            {categories?.length
              ? categories?.map(
                  (item: {
                    _id: Key | null | undefined;
                    image: any;
                    name: any;
                    description: string;
                  }) => (
                    <div
                      className="category_listing_single"
                      key={item?._id}
                      onClick={() => {
                        // navigate("/explore-subcategory");
                        navigate(`/explore-subcategory/${item?._id}`);
                      }}
                    >
                      <figure>
                        <img
                          src={
                            item?.image || "/images/category_attachment_1.jpg"
                          }
                          alt="Image"
                        />
                      </figure>
                      <h3>
                        {item?.name || "E Waste"}{" "}
                        <span>
                          {item?.description?.slice(0, 30) || "By remnynt"}
                        </span>
                      </h3>
                    </div>
                  )
                )
              : undefined}

            {/* <div
              className="category_listing_single"
              onClick={() => navigate("/explore-subcategory")}
            >
              <figure>
                <img src="/images/category_attachment_2.jpg" alt="Image" />
              </figure>
              <h3>
                Uni waste <span>By remnynt</span>
              </h3>
            </div> */}
          </div>
          {categories?.length ? (
            <SitePagination
              module={categories}
              page={page}
              onPageChange={onPageChange}
              totalPages={totalPages}
              setPage={setPage}
            />
          ) : undefined}
        </div>

        {!categories?.length && !loading ? (
          <div className="no_date_sc hd_4">
            <figure>
              <img src="/images/noCat.png" alt="noData" />
            </figure>
            <h2>No Category Found</h2>
            {/* <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry.
            </p> */}
            <a href="javascript:void(0)" className="btn btn_primary">
              Back to Home
            </a>
          </div>
        ) : undefined}
      </div>
    </section>
  );
};

export default ExploreCategory_Category;
