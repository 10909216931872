import { useNavigate } from "react-router-dom";

const CorporateSitesSection5 = () => {
    const navigate = useNavigate();

    return (
        <section className="Corp_sc5 u_spc" style={{ backgroundImage: "url(" + "/images/attachment-05.jpg" + ")" }}>
            <div className="conta_iner">
                <div className="s_hd text_white">
                    <h2>Give E-waste, Get Rewards</h2>
                    <div className="hd_4">
                        <h3>We incentivize recycling by offering NFTs as a reward for participating in our Impact2Earn recycling program.</h3>
                    </div>
                    <p>NFTs (Non-Fungible Tokens) are unique digital assets that are created on a blockchain, making them immutable and impossible to replicate. Once earned, these NFTs can then be exchanged in our marketplace for goods and services at a discount. This creates a virtuous cycle where recycling is incentivized, and the resulting NFTs can be used to drive sales for businesses that support sustainable practices.</p>
                    <button className="btn btn_lg btn_white">Get Your Rewards</button>
                </div>
            </div>
        </section>
    );
};

export default CorporateSitesSection5;
